import { toTitleCase } from "@/utils/string";
import Big from "big.js";
import { getPropertyAddress } from "@/utils/property";
import CustomError from "@/utils/error";
import ERROR_TYPE from "@/utils/error/errorType";

export default {
    data() {
        return {
            unitPriceCalculateOptions: ["Built Up", "Land"]
        };
    },
    methods: {
        getAreaValue() {
            let areaValue = 0;
            if (this.pricePerUnitOrigin == "Built Up") {
                areaValue =
                    this.property.builtUpArea == ""
                        ? 0
                        : this.property.builtUpArea;
            } else if (this.pricePerUnitOrigin == "Land") {
                areaValue =
                    this.property.landArea == "" ? 0 : this.property.landArea;
            }
            return areaValue;
        },
        setUnitAreaAndPrice(areaValue) {
            this.property = {
                ...this.property,
                ...this.updateUnitPrice({
                    salePrice: this.property.salePrice,
                    rentPrice: this.property.rentPrice,
                    unitArea: areaValue
                })
            };
        },
        calculateArea(width = 0, depth = 0) {
            try {
                width = width === "" ? 0 : width;
                depth = depth === "" ? 0 : depth;

                let w = new Big(width);
                let d = new Big(depth);

                let area = new Big(w.times(d).round(2).toFixed(2));

                return area.toNumber();
            } catch (error) {
                return 0;
            }
        },
        calculateUnitPrice(price, unit) {
            try {
                let p = new Big(price);
                let u = new Big(unit);

                let uPrice = new Big(p.div(u).round(2).toFixed(2));

                return uPrice;
            } catch (error) {
                return 0;
            }
        },
        updateUnitPrice({ salePrice = 0, rentPrice = 0, unitArea = 0 }) {
            // If there is a value defined 0, price per unit should be 0
            if (unitArea == 0) {
                return { salePricePerUnit: "0.00", rentPricePerUnit: "0.00" };
            }
            if (salePrice == 0) {
                return {
                    salePricePerUnit: "0.00",
                    rentPricePerUnit: this.calculateUnitPrice(
                        rentPrice,
                        unitArea
                    )
                };
            }
            if (rentPrice == 0) {
                return {
                    rentPricePerUnit: "0.00",
                    salePricePerUnit: this.calculateUnitPrice(
                        salePrice,
                        unitArea
                    )
                };
            }

            return {
                salePricePerUnit: this.calculateUnitPrice(salePrice, unitArea),
                rentPricePerUnit: this.calculateUnitPrice(rentPrice, unitArea)
            };
        },
        getOTPFullAddress() {
            try {
                this.checkAddressField(this.property);

                let addr = getPropertyAddress(this.property);
                return addr;
            } catch (error) {
                if (error.type == ERROR_TYPE.NOT_FOUND) {
                    this.$notify({
                        group: "alert",
                        type: "error",
                        title: "Data is not filled",
                        text: error
                    });
                }
            }
        },
        checkAddressField(property) {
            if (!property.lotNumber) {
                throw new CustomError(
                    "Lot Number is not set",
                    ERROR_TYPE.NOT_FOUND
                );
            } else if (!property.country) {
                throw new CustomError(
                    "Country is not set",
                    ERROR_TYPE.NOT_FOUND
                );
            } else if (!property.state) {
                throw new CustomError("State is not set", ERROR_TYPE.NOT_FOUND);
            } else if (!property.city) {
                throw new CustomError("City is not set", ERROR_TYPE.NOT_FOUND);
            } else if (!property.area) {
                throw new CustomError("Area is not set", ERROR_TYPE.NOT_FOUND);
            }
        }
    }
};
