<template>
  <fd-form
    class="property-form"
    @submit.prevent="submitForm"
    @error="submitError"
  >
    <fd-form-section title="Purpose" class="card p-2 md-p-3 md-p-3 mb-2">
      <!-- Purpose -->
      <selector
        class="col-12 row"
        v-model="property.purpose"
        :options="purposeOptions"
        :optionValue="
          (option) => {
            return option.id;
          }
        "
        :validators="[validators.required]"
        :disabled="purposeDisabled"
      >
        <template #beforeOption="{ hasError }">
          <span v-if="hasError" class="col-12 px-1 fg-danger"
            >Please select a purpose</span
          >
        </template>
        <template #option="{ option, active, select, hasError, disabled }">
          <span class="col-6 sm-col-3 p-1">
            <div
              class="purpose-selector p-2"
              :class="{
                active: active,
                error: hasError,
                disabled: disabled
              }"
              @click="
                () => {
                  select(option);
                  if (option.id == PURPOSE.AUCTION) {
                    getAuctionTypes();
                  }
                }
              "
            >
              {{ option.name.toUpperCase() }}
            </div>
          </span>
        </template>
      </selector>
    </fd-form-section>

    <fd-form-section
      v-if="property.purpose == PURPOSE.AUCTION"
      title="Auction Details"
      class="card p-2 md-p-3 mb-2"
    >
      <fd-input
        v-model="property.auctionTime"
        class="col-12 sm-col-6 px-1 mb-2"
        label="Auction Time"
        name="auctionTime"
        type="time"
        :validators="[validators.required]"
      >
      </fd-input>
      <fd-date-picker
        v-model="property.auctionDate"
        class="col-12 sm-col-6 px-1 mb-2"
        label="Auction Date"
        name="auctionDate"
        :validators="[validators.required]"
      >
      </fd-date-picker>
      <fd-input
        v-model="property.auctionVenue"
        class="col-12 sm-col-6 px-1 mb-2"
        label="Auction Venue"
        name="auctionVenue"
        type="text"
        :validators="[validators.required]"
      >
      </fd-input>
      <fd-select
        v-model="property.auctionTypeId"
        class="col-12 sm-col-6 px-1 mb-2"
        label="Auction Type"
        :options="auctionTypeOptions"
        :optionLabel="(opt) => opt.name"
        :optionValue="(opt) => opt.id"
        :validators="[validators.required]"
        :isLoading="isAuctionTypeLoading"
      >
      </fd-select>
      <fd-input
        v-model="property.assignee"
        class="col-12 sm-col-6 px-1 mb-2"
        label="Plaintiff/Assignee"
        name="assignee"
        type="text"
      >
      </fd-input>
      <fd-input
        v-model="property.auctioneer"
        class="col-12 sm-col-6 px-1 mb-2"
        label="Auctioneer"
        name="auctioneer"
        type="text"
      >
      </fd-input>
      <fd-input
        v-model="property.lawyer"
        class="col-12 sm-col-6 px-1 mb-2"
        label="Lawyer"
        name="lawyer"
        type="text"
      >
      </fd-input>
      <fd-input
        v-model="property.assignor"
        class="col-12 sm-col-6 px-1 mb-2"
        label="Defendant/Assignor"
        name="assignor"
        type="text"
      >
      </fd-input>
      <fd-input
        v-model="property.deposit"
        class="col-12 sm-col-6 px-1 mb-2"
        label="Deposit(%)"
        name="deposit"
        type="text"
        :validators="[validators.price]"
      >
      </fd-input>
      <div class="col-12 px-1 mt-2 mb-2">
        <file-uploader
          v-model="property.proclamationOfSale"
          label="Proclamation of Sale"
          :accept="acceptedFileType"
          :allowed="allowedFormat"
          :multiple="false"
          :isLoading.sync="isUploading"
        ></file-uploader>
      </div>
    </fd-form-section>
    <div class="card p-2 md-p-3 mb-2">
      <div class="row mt-2">
        <fd-form-section title="Property Details">
          <!-- Title -->
          <fd-input
            v-model="property.title"
            class="col-12 sm-col-6 md-col-3 px-1 mb-2"
            label="Header Name/Search Tag"
            name="propertyTitle"
            type="text"
            :validators="[validators.required]"
          >
          </fd-input>
          <!-- Property Category -->
          <fd-select
            v-model="property.propertyCategory"
            class="col-12 sm-col-6 md-col-3 px-1 mb-2"
            label="Property Category"
            name="propertyCategory"
            :options="propertyCategoryOptions"
            :validators="[validators.required]"
            @change="propertyCategoryChanged"
          >
          </fd-select>
          <!-- Property Type -->
          <fd-select
            v-model="property.propertyTypeId"
            class="col-12 sm-col-6 md-col-3 px-1 mb-2"
            label="Property Type"
            name="propertyTypeId"
            :selectText="
              $isStringEmpty(property.propertyCategory)
                ? 'Select property category first'
                : 'Choose an option'
            "
            :options="propertyTypeOptions"
            :validators="[validators.required]"
            :disabled="$isStringEmpty(property.propertyCategory)"
            @change="propertyTypeChanged"
          >
          </fd-select>
          <!-- Lot Type -->
          <fd-select
            v-model="property.lotTypeId"
            class="col-12 sm-col-6 md-col-3 px-1 mb-2"
            label="Lot Type"
            name="lotTypeId"
            :options="lotTypeOptions"
            :selectText="
              $isStringEmpty(property.propertyTypeId)
                ? 'Select property Type first'
                : 'Choose an option'
            "
            :disabled="$isStringEmpty(property.propertyTypeId)"
            :validators="[]"
          >
          </fd-select>
          <!-- Tenure Type -->
          <fd-multi-select
            v-model="property.tenureType"
            class="col-12 sm-col-6 md-col-3 px-1 mb-2"
            label="Tenure Type"
            name="tenureType"
            selectText="Select Tenure Type"
            :options="tenureTypeOptions"
          ></fd-multi-select>
          <!-- Furnishing Type -->
          <fd-select
            v-model="property.furnishingType"
            class="col-12 sm-col-6 md-col-3 px-1 mb-2"
            label="Furnishing Type"
            name="furnishingType"
            :options="furnishingTypeOptions"
          >
          </fd-select>
          <!-- Status -->
          <fd-select
            v-model="property.status"
            class="col-12 sm-col-6 md-col-3 px-1 mb-2"
            label="Select a status"
            :options="propertyStatusOptions"
            :validators="[validators.required]"
          >
          </fd-select>
        </fd-form-section>

        <!-- Address Details -->
        <fd-form-section title="Address Details">
          <addresses
            v-if="isEdit ? property.country : true"
            :isEdit="isEdit"
            :country.sync="property.country"
            :state.sync="property.state"
            :city.sync="property.city"
            :area.sync="property.area"
            :building.sync="property.building"
            :street.sync="property.street"
            :lat.sync="property.latitude"
            :lng.sync="property.longitude"
            class="row col-12"
            showBuilding
            showStreet
            showGeocode
            geocodeRequired
            @change="updateAddress"
          ></addresses>
          <fd-input
            v-model="property.lotNumber"
            class="col-12 sm-col-6 md-col-3 px-1 mb-2"
            label="Lot Number"
            name="lotNumber"
            type="text"
            :validators="[validators.required]"
          >
          </fd-input>
        </fd-form-section>

        <fd-form-section title="Land Details">
          <!-- Land Width -->
          <fd-input
            v-model="property.landWidth"
            class="col-12 sm-col-6 md-col-3 px-1 mb-2"
            label="Land Width"
            name="landWidth"
            type="number"
            @input="
              () => {
                property.landArea = calculateArea(
                  property.landWidth,
                  property.landDepth
                );

                let areaValue = 0;
                areaValue = getAreaValue();
                setUnitAreaAndPrice(areaValue);
              }
            "
          >
          </fd-input>
          <!-- Land Depth -->
          <fd-input
            v-model="property.landDepth"
            class="col-12 sm-col-6 md-col-3 px-1 mb-2"
            label="Land Depth"
            name="landDepth"
            type="number"
            @input="
              () => {
                property.landArea = calculateArea(
                  property.landWidth,
                  property.landDepth
                );

                let areaValue = 0;
                areaValue = getAreaValue();
                setUnitAreaAndPrice(areaValue);
              }
            "
          >
          </fd-input>
          <!-- Land Area -->
          <fd-input
            v-model="property.landArea"
            class="col-12 sm-col-6 md-col-3 px-1 mb-2"
            label="Land Area"
            name="landArea"
            type="number"
            @input="
              () => {
                if (pricePerUnitOrigin == 'Land') {
                  let areaValue = 0;
                  areaValue = getAreaValue();
                  setUnitAreaAndPrice(areaValue);
                }
              }
            "
            :validators="[validators.required]"
          >
          </fd-input>
          <!-- Area Unit Type -->
          <fd-select
            v-model="property.areaUnitType"
            class="col-12 sm-col-6 md-col-3 px-1 mb-2"
            label="Area Unit Type"
            name="areaUnitType"
            :options="areaUnitTypeOptions"
            :validators="[validators.required]"
          >
          </fd-select>
          <!-- Built Up Width -->
          <fd-input
            v-model="property.builtUpWidth"
            class="col-12 sm-col-6 md-col-3 px-1 mb-2"
            label="Built Up Width"
            name="builtUpWidth"
            type="number"
            @input="
              () => {
                property.builtUpArea = calculateArea(
                  property.builtUpWidth,
                  property.builtUpDepth
                );

                let areaValue = 0;
                areaValue = getAreaValue();
                setUnitAreaAndPrice(areaValue);
              }
            "
          >
          </fd-input>
          <!-- Built Up Depth -->
          <fd-input
            v-model="property.builtUpDepth"
            class="col-12 sm-col-6 md-col-3 px-1 mb-2"
            label="Built Up Depth"
            name="builtUpDepth"
            type="number"
            @input="
              () => {
                property.builtUpArea = calculateArea(
                  property.builtUpWidth,
                  property.builtUpDepth
                );

                let areaValue = 0;
                areaValue = getAreaValue();
                setUnitAreaAndPrice(areaValue);
              }
            "
          >
          </fd-input>
          <!-- Built Up Area -->
          <fd-input
            v-model="property.builtUpArea"
            class="col-12 sm-col-6 md-col-3 px-1 mb-2"
            label="Built Up Area"
            name="builtUpArea"
            type="number"
            @input="
              () => {
                if (pricePerUnitOrigin == 'Built Up') {
                  let areaValue = 0;
                  areaValue = getAreaValue();
                  setUnitAreaAndPrice(areaValue);
                }
              }
            "
            :validators="[validators.required]"
          >
          </fd-input>
        </fd-form-section>

        <fd-form-section
          title="Pricing Details"
          v-if="property.purpose != null"
        >
          <div class="row col-12 md-col-6">
            <!-- Sale Price -->
            <fd-input
              v-if="
                property.purpose == PURPOSE.SALE ||
                property.purpose == PURPOSE.SALE_AND_RENT ||
                property.purpose == PURPOSE.AUCTION
              "
              v-model="property.salePrice"
              class="col-12 px-1 mb-2"
              label="Sale Price (RM)"
              name="salePrice"
              type="number"
              @input="
                () => {
                  let areaValue = 0;
                  areaValue = getAreaValue();

                  property.salePricePerUnit = calculateUnitPrice(
                    property.salePrice,
                    areaValue
                  );
                }
              "
              :validators="[validators.required, validators.price]"
            >
            </fd-input>
            <!-- Rent Price -->
            <fd-input
              v-if="
                property.purpose == PURPOSE.RENT ||
                property.purpose == PURPOSE.SALE_AND_RENT
              "
              v-model="property.rentPrice"
              class="col-12 px-1 mb-2"
              label="Rent Price (RM)"
              name="rentPrice"
              type="number"
              @input="
                () => {
                  let areaValue = 0;
                  areaValue = getAreaValue();

                  property.rentPricePerUnit = calculateUnitPrice(
                    property.rentPrice,
                    areaValue
                  );
                }
              "
              :validators="[validators.required, validators.price]"
            >
            </fd-input>
            <!-- Unit Price Type -->
            <fd-select
              v-model="pricePerUnitOrigin"
              class="col-12 px-1 mb-2"
              label="Unit Price Calculate With"
              name="unitPriceType"
              :options="unitPriceCalculateOptions"
              :optionValue="
                (option) => {
                  return option;
                }
              "
              :optionLabel="
                (option) => {
                  return option;
                }
              "
              @change="
                () => {
                  let areaValue = 0;
                  areaValue = getAreaValue();
                  setUnitAreaAndPrice(areaValue);
                }
              "
              :validators="[validators.required]"
            >
            </fd-select>
          </div>
          <div class="row col-12 md-col-6">
            <div class="unit-price-container">
              <div
                class="info-container"
                v-if="
                  property.purpose == PURPOSE.SALE ||
                  property.purpose == PURPOSE.SALE_AND_RENT ||
                  property.purpose == PURPOSE.AUCTION
                "
              >
                <label class="title">Sale Price Per Unit</label>
                <p>
                  RM{{ property.salePricePerUnit || "0.00" }}
                  {{
                    property.areaUnitType != ""
                      ? `per ${property.areaUnitType}`
                      : ""
                  }}
                </p>
              </div>
              <div
                class="info-container"
                v-if="
                  property.purpose == PURPOSE.RENT ||
                  property.purpose == PURPOSE.SALE_AND_RENT
                "
              >
                <label class="title">Rent Price Per Unit</label>
                <p>
                  RM{{ property.rentPricePerUnit || "0.00" }}
                  {{
                    property.areaUnitType != ""
                      ? `per ${property.areaUnitType}`
                      : ""
                  }}
                </p>
              </div>
            </div>
          </div>
        </fd-form-section>

        <fd-form-section title="Facility Details">
          <!-- Number of Bedroom -->
          <fd-input
            v-model="property.numberOfBedroom"
            class="col-12 sm-col-6 md-col-3 px-1 mb-2"
            label="Number of Bedroom"
            name="numberOfBedroom"
            type="number"
            :validators="[]"
          >
          </fd-input>
          <!-- Number of Bathroom -->
          <fd-input
            v-model="property.numberOfBathroom"
            class="col-12 sm-col-6 md-col-3 px-1 mb-2"
            label="Number of Bathroom"
            name="numberOfBathroom"
            type="number"
            :validators="[]"
          >
          </fd-input>
          <!-- Property Facilities -->
          <fd-multi-select
            v-model="property.propertyFacilities"
            class="col-12 sm-col-6 md-col-3 px-1 mb-2"
            label="Property Facilities"
            selectText="Select facilities"
            :options="propertyFacilitiesOptions"
            :optionValueKey="`id`"
            :optionLabelKey="`name`"
          ></fd-multi-select>
          <!-- Property Amenities -->
          <fd-multi-select
            v-model="property.propertyAmenities"
            class="col-12 sm-col-6 md-col-3 px-1 mb-2"
            label="Property Amenities"
            selectText="Select amenities"
            :options="propertyAmenitiesOptions"
            :optionValueKey="`id`"
            :optionLabelKey="`name`"
          >
          </fd-multi-select>
        </fd-form-section>

        <fd-form-section title="Images">
          <!-- Main Photo (Image Upload) -->
          <div class="col-12 px-1 mb-2 main-photo">
            <image-uploader
              v-model="property.mainPhoto"
              label="Main Photo"
              :multiple="false"
              watermark
              required
              @error="
                (error, imageName) => {
                  $reportError(error, 'Upload mainPhoto (Edit Property)');
                }
              "
            >
            </image-uploader>
          </div>

          <!-- Gallery (Image Upload) -->
          <div class="col-12 mb-2 px-1 gallery">
            <image-uploader
              v-model="property.gallery"
              label="Gallery"
              multiple
              watermark
              @error="
                (error, imageName) => {
                  $reportError(error, 'Upload gallery (Edit Property)');
                }
              "
            >
            </image-uploader>
          </div>
        </fd-form-section>

        <!-- Property Owner -->
        <fd-form-section
          v-if="property.purpose !== PURPOSE.AUCTION"
          title="Property Owner"
        >
          <owner-property-code
            v-if="!_.isEmpty(property) && showOwnerCode"
            class="col-12 mb-3"
            v-model="property.ownerPropertyCode"
            isEdit
            :propertyId="property.id"
            :confirmed="property.ownerPropertyCodeConfirm"
            @loading="
              (loading) => {
                isLoading = loading;
              }
            "
          ></owner-property-code>

          <fd-input
            v-model="property.propertyOwner.name"
            class="col-12 sm-col-6 md-col-3 px-1 mb-2"
            label="Name"
            name="propertyOwnerName"
            type="text"
            :validators="[validators.required]"
          ></fd-input>
          <fd-input
            v-model="property.propertyOwner.contact"
            class="col-12 sm-col-6 md-col-3 px-1 mb-2"
            label="Contact"
            name="propertyOwnerContact"
            type="text"
            :validators="[validators.required]"
          ></fd-input>
          <fd-input
            v-model="property.propertyOwner.email"
            class="col-12 sm-col-6 md-col-3 px-1 mb-2"
            label="Email"
            name="propertyOwnerEmail"
            type="email"
          ></fd-input>
          <fd-input
            v-model="property.propertyOwner.remark"
            class="col-12 sm-col-6 md-col-3 px-1 mb-2"
            label="Remark"
            name="propertyOwnerRemark"
            type="text"
          ></fd-input>
        </fd-form-section>

        <fd-form-section title="Description">
          <!-- Description -->
          <basic-tip-tap
            class="mb-2"
            v-model="property.description"
          ></basic-tip-tap>
        </fd-form-section>
      </div>
    </div>

    <collapsible v-model="isAdditionalInfoShown" class="card p-1 mb-1">
      <template #title>
        <h4 class="mainTitle">Additional Info</h4>
      </template>
      <div class="row p-2 sm-p-3 mt-2">
        <fd-form-section title="Description Info">
          <!-- Video Link -->
          <fd-input
            v-model="property.videoLink"
            class="col-12 sm-col-6 px-1 mb-2"
            label="Video Link"
            name="videoLink"
            type="text"
          ></fd-input>
          <!-- VR Link -->
          <fd-input
            v-model="property.vrLink"
            class="col-12 sm-col-6 px-1 mb-2"
            label="VR Link"
            name="vrLink"
            type="text"
          ></fd-input>

          <!-- Property Tags -->
          <fd-tags-input
            v-model="property.propertyTags"
            class="col-12 sm-col-6 px-1 mb-2"
            label="Property Tags"
            :transformText="(text) => text.toLowerCase()"
            :isDuplicate="
              (tags, tag) =>
                tags
                  .map((t) => t.text.toLowerCase())
                  .indexOf(tag.text.toLowerCase()) !== -1
            "
            :autocompleteItems="propertyTagOptions"
            :tagValidation="propertyTagValidations"
            :separators="[',', ';', ' ']"
            @change="
              (tags) => {
                property.hashtag = tags.map((tag) => tag.text).join(',');
              }
            "
          ></fd-tags-input>
        </fd-form-section>

        <!-- Property Remark -->
        <fd-form-section title="Personal Remark">
          <fd-textarea
            v-model="property.remark"
            class="col-12 px-1 mb-2"
            name="propertyRemark"
          >
          </fd-textarea>
        </fd-form-section>

        <fd-form-section title="Renovation Details">
          <!-- Renovation Cost -->
          <fd-input
            v-model="property.renovationCost"
            class="col-12 sm-col-6 md-col-3 px-1 mb-2"
            label="Renovation Cost"
            name="renovationCost"
            type="number"
          >
          </fd-input>
          <!-- Renovation Detail -->
          <fd-textarea
            v-model="property.renovationDetail"
            class="col-12 px-1 mb-2"
            label="Renovation Detail"
            name="renovationDetail"
          >
          </fd-textarea>
        </fd-form-section>

        <!-- Commission Details -->
        <fd-form-section
          title="Commission Details"
          v-if="property.purpose && property.purpose != PURPOSE.AUCTION"
        >
          <div
            class="col-12 md-col-6"
            v-if="
              property.purpose == 'Sale' || property.purpose == 'Sale and Rent'
            "
          >
            <fd-select
              v-model="property.propertyCommission.salesCommissionType"
              class="px-1 mb-2"
              label="Sales Commission Type"
              :options="salesCommissionTypeOptions"
              :validators="
                !$isStringEmpty(
                  property.propertyCommission.salesCommissionValue
                )
                  ? [validators.required]
                  : []
              "
            >
            </fd-select>
            <fd-input
              v-model="property.propertyCommission.salesCommissionValue"
              class="px-1 mb-2"
              label="Sale Commission"
              name="salesCommission"
              type="number"
              step="any"
            >
            </fd-input>
          </div>
          <div
            class="col-12 md-col-6"
            v-if="
              property.purpose == 'Rent' || property.purpose == 'Sale and Rent'
            "
          >
            <fd-input
              v-model="property.propertyCommission.rentCommissionAmount"
              class="px-1 mb-2"
              label="Rent Commission"
              name="rentCommission"
              type="number"
              step="any"
            >
            </fd-input>
          </div>
        </fd-form-section>
      </div>
    </collapsible>

    <collapsible v-if="showOTP" v-model="isOTPInfoShown" class="card p-1 mb-1">
      <template #title>
        <h4 class="mainTitle py-2">OTP Info</h4>
      </template>
      <div class="row p-2 sm-p-3 mt-2">
        <!-- OTP Form -->
        <subsales-otp-form
          isEdit
          class="mb-3"
          :autofillAddress="getOTPFullAddress"
          :otpData="property.offerToPurchase"
          @change="
            (otp) => {
              this.property.offerToPurchase = otp;
            }
          "
        ></subsales-otp-form>
      </div>
    </collapsible>

    <collapsible v-if="showOTR" v-model="isOTRInfoShown" class="card p-1 mb-1">
      <template #title>
        <h4 class="mainTitle py-2">OTR Info</h4>
      </template>
      <div class="row p-2 sm-p-3 mt-2">
        <!-- OTR Form -->
        <rent-otr-form
          isEdit
          class="mb-3"
          :showTenant="false"
          :autofillAddress="getOTPFullAddress"
          :otrData="property.offerToRent"
          @change="
            (otr) => {
              this.property.offerToRent = otr;
            }
          "
        ></rent-otr-form>
      </div>
    </collapsible>

    <div class="text-right">
      <button
        type="submit"
        class="btn main medium fixed round"
        :disabled="isLoading"
      >
        <span v-if="isLoading">
          <spinner
            size="16px"
            color="white"
            class="d-inline-block mr-1"
          ></spinner>
          Please Wait
        </span>

        <span v-else> <i class="fas fa-pen mr-1"></i>Save </span>
      </button>
    </div>
  </fd-form>
</template>

<script>
// Enums
import {
  propertyCategory,
  tenureType,
  furnishingType,
  purpose,
  areaUnitType,
  propertyStatus,
  salesCommissionType
} from "@/enums";
// Validators
import {
  required,
  minLength,
  email,
  phone,
  fax,
  price
} from "@/components/GlobalComponents/FormComponents/Validator/rules";
// Mixin
import managePropertyMixin from "@/views/manageProperty/mixin";
import vueAutoSuggest from "@/mixins/vue-autosuggest/mixin";
// Utils
import debounce from "lodash/debounce";
// API
import { auctionType as auctionTypeAPI } from "@/api";

export default {
  components: {
    Selector: () =>
      import("@/components/GlobalComponents/FormComponents/Selector"),
    Addresses: () => import("@/components/GlobalComponents/Address"),
    SubsalesOtpForm: () =>
      import("@/components/GlobalComponents/Shared/Subsales/OTPForm"),
    RentOtrForm: () =>
      import("@/components/GlobalComponents/Shared/Rent/OTRForm"),
    OwnerPropertyCode: () =>
      import("@/components/ManageProperty/OwnerPropertyCodeInput"),
    FdMultiSelect: () =>
      import("@/components/GlobalComponents/FormComponents/FdMultiSelect"),
    FileUploader: () => import("@/components/GlobalComponents/FileUploader"),
    ImageUploader: () => import("@/components/GlobalComponents/ImageUploader"),
    BasicTipTap: () => import("@/components/GlobalComponents/BasicTipTap"),
    Collapsible: () => import("@/components/GlobalComponents/Collapsible"),
    Spinner: () =>
      import("@/components/GlobalComponents/LoaderComponent/Spinner")
  },
  mixins: [vueAutoSuggest, managePropertyMixin],
  props: {
    form: {
      type: Object
    },
    isEdit: {
      type: Boolean,
      default: true
    },
    showOwnerCode: {
      type: Boolean,
      default: true
    },
    purposeDisabled: {
      type: Boolean,
      default: false
    }
  },
  data: function () {
    return {
      isAdditionalInfoShown: false,
      isOTPInfoShown: false,
      isOTRInfoShown: false,
      isLoading: false,

      // Address
      streetOptions: [{ data: [] }],
      propertyFacilitiesOptions: [],
      propertyAmenitiesOptions: [],
      propertyTagOptions: [],

      // Property
      propertyTypeOptions: [],
      lotTypeOptions: [],
      tenureTypeOptions: this.$mapJsonToArray(tenureType, (e) => {
        return e;
      }),
      propertyCategoryOptions: this.$mapJsonToArray(propertyCategory, (e) => {
        return {
          id: e,
          name: e
        };
      }),
      furnishingTypeOptions: this.$mapJsonToArray(furnishingType, (e) => {
        return {
          id: e,
          name: e
        };
      }),
      purposeOptions: this.$mapJsonToArray(purpose, (e) => {
        return {
          id: e,
          name: e
        };
      }),
      areaUnitTypeOptions: this.$mapJsonToArray(areaUnitType, (e) => {
        return {
          id: e,
          name: e
        };
      }),
      propertyStatusOptions: this.$mapJsonToArray(propertyStatus, (e) => {
        return {
          id: e,
          name: e
        };
      }),
      salesCommissionTypeOptions: this.$mapJsonToArray(
        salesCommissionType,
        (e) => {
          return {
            id: e,
            name: e
          };
        }
      ),

      pricePerUnitOrigin: "Built Up",

      isUploading: false,
      isAuctionTypeLoading: false,
      auctionTypeOptions: [],

      propertyStatus: "",
      PURPOSE: purpose,

      // Validators
      validators: {
        required,
        minLength,
        email,
        phone,
        fax,
        price
      },
      propertyTagValidations: [
        {
          classes: "alphanumeric",
          rule: /^[a-z0-9]+$/i,
          disableAdd: true
        }
      ],
      acceptedFileType:
        "application/msword,application/vnd.ms-excel,application/vnd.ms-powerpoint,application/pdf,image/*",
      allowedFormat: ["pdf", "doc", "docx", "odt", "ods", "jpg", "jpeg", "png"]
    };
  },
  computed: {
    property: {
      get() {
        return this.form;
      },
      set(value) {
        this.$emit("update:form", { ...this.form, value });
      }
    },
    showOTP() {
      return (
        this.property.purpose == purpose.SALE ||
        this.property.purpose == purpose.SALE_AND_RENT
      );
    },
    showOTR() {
      return (
        this.property.purpose == purpose.RENT ||
        this.property.purpose == purpose.SALE_AND_RENT
      );
    }
  },
  watch: {},
  created: function () {},
  beforeDestroy: function () {},
  mounted: function () {
    this.init();
  },
  methods: {
    async init() {
      await Promise.all([
        this.getPropertyFacilities(),
        this.getPropertyAmenities(),
        this.getPropertyTags()
      ]);

      await this.getAuctionTypes();

      await this.getPropertyTypes(this.property.propertyCategory).then(
        (data) => {
          this.propertyTypeOptions = this._.cloneDeep(data);
        }
      );
      await this.getLotTypes(this.property.propertyTypeId).then((data) => {
        this.lotTypeOptions = this._.cloneDeep(data);
      });
    },
    updateAddress(data) {
      this.property.countryId = data.country?.id;
      this.property.stateId = data.state?.id;
      this.property.cityId = data.city?.id;
      this.property.areaId = data.area?.id;
      this.property.buildingId = data.building?.id;
    },

    propertyCategoryChanged(val) {
      this.$store.commit("setIsLoading", true);
      this.property.propertyTypeId = "";
      this.property.lotTypeId = "";
      this.getPropertyTypes(val).then((data) => {
        this.propertyTypeOptions = this._.cloneDeep(data);
        this.$store.commit("setIsLoading", false);
      });
    },

    propertyTypeChanged(val) {
      this.$store.commit("setIsLoading", true);
      this.property.lotTypeId = "";
      this.getLotTypes(val).then((data) => {
        this.lotTypeOptions = this._.cloneDeep(data);
        this.$store.commit("setIsLoading", false);
      });
    },

    streetInput: debounce(function () {
      let vm = this;
      // Query from backend
      this.getStreets(this.property.street).then((data) => {
        let suggestions = vm.filterResults(data, vm.property.street, "name");
        vm.streetOptions = []; // Clear suggestions
        vm.streetOptions.push({ data: suggestions }); // push suggestions
      });
    }, 300),
    streetSelected(selected) {
      this.property.street = selected.item.name;
    },

    validateDepositRates(value1, value2) {
      if (Number(value1) + Number(value2) > 10) {
        this.$notify({
          group: "alert",
          type: "error",
          title: "OTP Total Deposit Limit",
          text:
            "Sum of first deposit and second deposit rate should be more than 10%"
        });
        return false;
      }
      return true;
    },

    submitError(err) {
      if (err[0].name)
        this.$notify({
          group: "alert",
          type: "error",
          title: err[0].label,
          text: err[0].message
        });
    },

    // ============================== API Related ==============================
    async getAuctionTypes() {
      try {
        this.isAuctionTypeLoading = true;
        let data = await auctionTypeAPI.getAuctionTypes();
        this.auctionTypeOptions = this._.cloneDeep(data);
        this.isAuctionTypeLoading = false;
      } catch (error) {
        this.$notify({
          group: "alert",
          type: "error",
          title: "Error",
          text: "Failed to fetch auction type options."
        });
        this.isAuctionTypeLoading = false;
      }
    },
    async getPropertyTypes(category) {
      let queries = {};
      queries.propertyCategory = category;
      try {
        let data = await this.$store.dispatch(
          "manageProperty/getPropertyTypes",
          { queries: queries }
        );
        return data;
      } catch (error) {
        throw error;
      }
    },
    async getLotTypes(id) {
      try {
        let data = await this.$store.dispatch("manageProperty/getLotTypes", {
          queries: {
            "propertyType:id": id
          }
        });
        return data;
      } catch (error) {
        throw error;
      }
    },
    async getStreets(name) {
      try {
        let data = await this.$store.dispatch("manageProperty/getStreets", {
          areaId: this.property.areaId,
          name: name
        });

        return data;
      } catch (error) {
        throw error;
      }
    },
    async getPropertyFacilities() {
      try {
        let data = await this.$store.dispatch(
          "manageProperty/getPropertyFacilities"
        );
        this.propertyFacilitiesOptions = this._.cloneDeep(data);
      } catch (error) {
        this.$notify({
          group: "alert",
          type: "error",
          title: "Error",
          text: "Failed to fetch facility options. Please try again later."
        });
        throw error;
      }
    },
    async getPropertyAmenities() {
      try {
        let data = await this.$store.dispatch(
          "manageProperty/getPropertyAmenities"
        );

        this.propertyAmenitiesOptions = this._.cloneDeep(data);
      } catch (error) {
        this.$notify({
          group: "alert",
          type: "error",
          title: "Error",
          text: "Failed to fetch amenities options. Please try again later."
        });
        throw error;
      }
    },
    async getPropertyTags() {
      try {
        this.$store.commit("setIsLoading", true);
        let data = await this.$store.dispatch("manageProperty/getPropertyTags");
        let mappedData = data.map((tag) => ({ text: tag.name }));

        this.propertyTagOptions = this._.cloneDeep(mappedData);
        this.$store.commit("setIsLoading", false);
      } catch (error) {
        this.$store.commit("setIsLoading", false);
        this.propertyTagOptions = [];
        throw error;
      }
    },
    submitForm() {
      if (
        this.validateDepositRates(
          this.property.offerToPurchase.depositRate1,
          this.property.offerToPurchase.depositRate2
        )
      ) {
        this.$emit("submit", this.property);
      }
    }
  }
};
</script>

<style lang="scss">
.property-form {
  @extend %formDesign;
  .unit-price-container {
    @include flex(row, flex-start, flex-start);
    // border: 1px solid #ddd;
    border-radius: 5px;
    margin: 0 -8px;
    flex: 1;
    .info-container {
      margin: 12px 16px;
      .title {
        font-weight: 600;
        font-size: 13px;
      }
      p {
        color: grey;
      }
    }
  }

  .purpose-selector {
    border: solid 2px #eee;
    color: #5f5f5f;
    border-radius: 8px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    &:hover {
      background: #eee;
    }
    &.active {
      border: solid 2px $color-main;
      background: $color-main;
      color: white;
      &:hover {
        border-color: lighten($color-main, 10);
        background: lighten($color-main, 10);
      }
    }
    &.error {
      border: solid 1px $color-danger;
      color: $color-danger;
    }
    &.disabled {
      background: #eee;
      cursor: not-allowed;
      &.active {
        background: lighten($color-main, 25);
      }
    }
  }
}
</style>
